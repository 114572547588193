body,
html {
    font-family: 'Montserrat', sans-serif;
    overflow: hidden auto;
}

.MuiTableCell-root {
    padding: 0 !important;
}

a,
span,
.MuiTypography-root,
label,
input,
ul,
ul li,
.MuiDataGrid-columnHeaderTitle,
.MuiOutlinedInput-input,
.MuiOutlinedInput-root,
.MuiSelect-select {
    font-family: 'Montserrat' !important;
}

.pointer {
    cursor: pointer;
}

.MuiDataGrid-columnHeaderTitle {
    font-weight: 600 !important;
    color: #555555 !important;
}

.MuiDataGrid-columnSeparator--sideRight {
    display: none !important;
}


.MuiDataGrid-cellContent {
    font-family: 'Montserrat';
}

.MuiDataGrid-row .MuiDataGrid-cell {
    border-bottom: none !important;
}

.db {
    display: block !important;
    width: 100%;
    font-family: 'Montserrat';
}

.h-100 {
    height: 100%;
}

.pb-30 {
    padding-bottom: 30px;
}

.pb-30:first-child {
    padding-top: 30px;
}

.inline-block {
    display: inline-block;
}

.dib {
    display: inline-block;
}

.dib:not(:first-child) {
    margin-left: 40px;
}

.toolbar {

    margin-bottom: 16px;
    display: flex;
    align-items: center;
}

.toolbar fieldset {
    border-radius: 35px !important;
    border-color: #EEE !important;
}

.toolbar input {
    font-size: 1rem !important;
    line-height: 19px !important;
    font-family: 'Montserrat' !important;
    font-weight: 400 !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.min-w-240 {
    min-width: 240px !important;
}

.mt-20 {
    margin-top: 20px;
}

.MuiDataGrid-row:nth-child(2n-1) {
    background-color: #F9F9F9 !important;
}

.MuiDialog-paperWidthLg {
    min-height: calc(100vh - 40px);
}

.MuiDialog-paperWidthLg .MuiDataGrid-main {
    min-height: calc(100vh - 295px) !important;
}

.MuiDataGrid-row {
    max-height: none !important;
}

.MuiDataGrid-columnHeaders {
    background-color: #FFFFFF;
    font-size: 12;
}

.MuiDataGrid-checkboxInput {
    color: #1f9997;
}

.MuiDataGrid-checkboxInput.Mui-checked {
    color: #1f9997,
}

.MuiDataGrid-renderingZone {
    max-height: none !important;
}

.MuiDataGrid-cell {
    line-height: unset !important;
    max-height: none !important;
    white-space: normal !important;
    word-wrap: break-word !important;
    font-size: 16px;
}

.MuiDataGrid-root {
    border: none !important;
}

.MuiDataGrid-virtualScroller {
    margin-top: 35px !important;
}

.MuiDataGrid-columnHeaders,
.MuiDataGrid-columnHeadersInner,
.MuiDataGrid-columnHeader,
.MuiDataGrid-columnHeaderDraggableContainer {
    min-height: 0 !important;
    min-width: 0 !important;
    max-height: 30px !important;
    border-bottom: none !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer {
    visibility: visible !important;
    width: 0 !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon {
    opacity: 0.3 !important;
}

.unchecked {
    opacity: 0 !important;
}

.unchecked:hover {
    cursor: 'pointer' !important;
    opacity: 1 !important;
}

.min-h-500 {
    min-height: 500px;
}

.min-h-350 {
    min-height: 350px;
}

.edit-title {
    color: white;
    height: 40px;
    vertical-align: middle;
    padding-left: 30px;
    padding-top: 8px;
    font-size: 18px;
}

.edit-select,
.input {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 0.75px solid #BDBDBD !important;
    border-radius: 0 !important;
    width: 250px;
    height: 40px;
    padding-left: 10px;

}

.edit-input {
    border: 0.75px solid #BDBDBD !important;
    border-radius: 0 !important;
}

.edit-input fieldset,
.edit-select fieldset {
    border: none;
}

/* 
.input {
    margin-right: 20px;
    
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid lightgrey;
    padding-left: 18px;
    padding-top: 18px;
    padding-bottom: 18px;

} */

.input:focus,
.edit-input:focus {
    outline: none !important;
    border: 2px solid #3f50b5 !important;
}

.delete-form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
}

.flex {
    display: flex;
}

.font-14 {
    font-size: 14px;
    padding-top: 14px;
}

.MuiTypography-h6 {
    letter-spacing: 0px !important;
    color: #636364 !important;
    font-size: 18px !important;
    line-height: 40px !important;
    font-weight: 600 !important;
}

.min-w-340 {
    min-width: 340px !important;
    margin-right: 60px !important;
}

.config {
    letter-spacing: 0px !important;
    color: #636364 !important;
}

.checkbox-label .MuiTypography-body1 {
    color: #353636 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.day-of-week {
    padding-top: 15px;
    padding-bottom: 15px;
    background: #E8F3F4 0% 0% no-repeat padding-box;
    width: 12%;
    text-align: center;
    margin-left: 4px !important;
    border-radius: 5px;
    font-family: 'Montserrat' !important;
    font-size: 12px;
    color: #555555;
    text-transform: uppercase;
}

.day-of-week-row {
    height: 31px;
    background: rgb(222, 222, 222) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    width: 12%;
    margin-left: 4px !important;
    cursor: pointer;
}

.day-of-week-row-selected {
    background: #5DC261 0% 0% no-repeat padding-box;
}

.day-of-week:first-child {
    background-color: white;
    width: 80px;
}

.day-of-week-hour {
    width: 85px;
    background: #E8F3F4 0% 0% no-repeat padding-box;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #555555;
    text-transform: uppercase;
    font-size: 12px;
}

.latency-legend {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 16px;
    padding-top: 16px;
}

.latency-legend div {
    width: 28px;
    height: 28px;
    margin-left: 24px;
}

.MuiTypography-subtitle3 {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Montserrat';
    padding-left: 12px;
}

.semi-bold {
    font-weight: 600 !important;
}

.bg-warning {
    background-color: #FF7D42;
}

.latency-legend div:first-child {
    background-color: #4CAF50;
}

.failure {
    background-color: #CB1919;
}

.pt-2 {
    padding-top: 2px;
}

.pb-2 {
    padding-bottom: 2px;
}

.round-button {
    box-shadow: 0px 3px 6px #00000060 !important;
    border-radius: 16px !important;
    width: 118px !important;
    font-size: 13px !important;
    text-transform: capitalize !important;
    background: #1F9997 0% 0% no-repeat padding-box !important;
}

.big-button {
    min-width: 144px !important;
    margin-top: 8px !important;
}

.capitalize {
    text-transform: capitalize !important;
}

.Mui-disabled {
    opacity: .5;
}

.MuiTypography-h7 {
    font-size: 18px !important;
    color: #000 !important;
    font-weight: 400 !important;
}

.MuiTypography-h8 {
    font-size: 18px !important;
    color: #000 !important;
    font-weight: 500 !important;
    padding-left: 4px;
}

.underscore {
    text-decoration: underline;
    color: #03A9F4;
}

.Passed,.PASS {
    color: #4CAF50 !important;
    font-weight: bold !important;
}

.Warning {
    color: #FF7D42 !important;
    font-weight: bold !important;
}

.Failed,
.Stopped,
.FAILED {
    color: #CB1919 !important;
    font-weight: bold !important;
}

.row_0 {
    background: #F9F9F9 0% 0% no-repeat padding-box !important;
}

.header-run {
    background: #E8F3F4 0% 0% no-repeat padding-box;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 27px;
}

.border-run {
    box-shadow: 0px 3px 6px #00000029;
    margin-top: 20px;
}

.step-run-table thead {
    height: 68px;
}

.step-run-table tbody td {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-family: 'Montserrat';
    font-size: 16px !important;
    padding-left: 20px !important;
}

.step-run-table thead th {
    padding-top: 9px;
    padding-bottom: 12px;
    letter-spacing: 0.6px;
    color: #555555;
    text-transform: uppercase;
    font-family: 'Montserrat';
    padding-left: 20px !important;
    font-weight: 600;
    font-size: 12px;
}

.step-run-table thead th:last-child {
    padding-right: 10px !important;
}

.mb-2 {
    margin-bottom: 16px;
}

.rotate-180 {
    transform: rotate(180deg);
}

.apexcharts-heatmap-series {
    padding: 23px !important;
}

.dashboard-mobile-kpi {
    display: block;
    width: 100%;
    padding-top: 16px;
}

.dashboard-mobile-kpi div {
    width: 20%;
    display: inline-block;
    padding: 3px;
    margin: 0;
    vertical-align: top;
    margin-left: 8px;
}

.dashboard-flex-totals {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    width: 100% !important;
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.dashboard-flex-totals p {
    text-align: center !important;
}

.p-2 {
    padding: 2px !important;
}

.mobile-alert-settings .MuiTypography-h8,
.mobile-alert-settings .MuiTypography-h7 {
    font-size: 16px !important;
}

.date-picker-dashboard {
    display: block;
    width: 100%;
}

.date-picker-dashboard div {
    display: inline-block;
    width: 87%;
    vertical-align: middle;
}

.date-picker-dashboard div.border {
    border: 1px solid #BDBDBD;
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: 98%;
}

.date-picker-dashboard div.border div {
    padding-left: 16px;
    width: 100%;
}

.w-full {
    width: 100%;
}

.MuiAccordion-root {
    box-shadow: none !important;

}

.MuiAccordion-root .MuiTypography-root {
    font-size: 10px !important;
}

.MuiAccordion-root.MuiAccordion-rounded::before {
    width: 0 !important;
    height: 0 !important;
}

.w-80 {
    width: 80%;
}

.w-20 {
    width: 15%;
}

.w-40 {
    width: 40%;
}

.w-50 {
    width: 50%;
}

.title-run-result {
    font-size: 23px;
    font-weight: 700;
    padding-left: 30px;
}

.pt-40 {
    padding-top: 50px;
}

.summary-total {
    top: 90px;
    left: 65px
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.mobile-view-toolbar-list {
    position: absolute;
    width: 90%;
    text-align: right;
    padding-left: 0;
    top: 64px;
}

.mobile-footer-menu {
    position: fixed;
    bottom: 0;
    height: 65px;
    background-color: #fff;
    width: 100%;
    display: flex;
    z-index: 9999;
    box-shadow: 4px -1px 3px #00000021;
    align-items: center;
}

.mobile-footer-menu div {
    width: 20%;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 95%;
}

.mb-view {
    margin-bottom: 65px;
}

.selected-nav-bar {
    border-bottom-color: #1F9997;
    border-bottom-width: 4px;
    border-bottom-style: solid;
}

.right {
    text-align: right;
}

#waveform wave {
    height: 130px !important;
    overflow: hidden !important;
    border-right: none !important;
}

#waveform canvas {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
}

.flex-item {
    flex-grow: 1;
}

.controls {
    position: absolute;
    margin-top: -45px;
    margin-left: -50px;
}

.waver-border {
    border-left: solid 2px #000;
}

.error {
    color: red;
    font-size: 12px;
}

.wavesurfer-region {
    background-color: transparent !important;
}

.wavesurfer-region .wavesurfer-handle {
    display: none !important;
}

.wavesurfer-marker div {
    background: transparent !important;
    font-size: 12px !important;
    z-index: 999999 !important;
}

.wavesurfer-region .wavesurfer-handle {
    height: 20px !important;
}

region.wavesurfer-region:before {
    content: attr(data-region-label);
    padding-left: 4px;
    font-size: 12px;
}

region:last-of-type .wavesurfer-handle {
    display: none;
}

.mb-20 {
    margin-bottom: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.modal-close-icon {
    position: absolute !important;
    right: 4px;
    top: 16px;
    color: #000 !important;
}

.login-container {
    width: 775px;
    height: 485px;
    background: #FFFFFF;
    box-shadow: 2px 4px 4px rgba(31, 153, 151, 0.2);
    border-radius: 20px;
    margin: 0 auto;
    margin-top: 7%;
    display: flex;
}

.login-container input {
    font-size: 12px !important;
    min-width: 200px !important;
}

.f-12 .MuiTypography-body1 {
    font-size: 13px !important;
    color: #484848 !important;
}

.login-banner {
    background: linear-gradient(180deg, rgba(100, 218, 183, 0.8) 0%, rgba(32, 102, 151, 0.8) 100%);
    border-radius: 20px 0px 0px 20px;
    width: 297.48px;
    height: 100%;
    position: relative;
}

.tekvisionlogo {
    position: absolute;
    z-index: 3;
    bottom: 30px;
    margin-left: 69px;
}

.login-header-title {
    font-weight: 400 !important;
    font-size: 23px !important;
    line-height: 28px !important;
    color: #000000 !important;
}

.v-top {
    vertical-align: top;
}

.main-login {
    display: block;
    text-align: left;
    padding-top: 30px;
}

.pl-80 {
    padding-left: 81px !important;
}

.pt-69 {
    margin-top: 69px !important;
}

.pt-20 {
    padding-top: 20px;
}

.mt-59 {
    margin-top: 59px;
}

.mt-14 {
    margin-top: 14px;
}

.login-button {

    width: 187.6px;
    height: 37.52px;
    background: linear-gradient(92.22deg, #64DAB7 11.22%, #1F9997 94.98%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 18px !important;
    color: white !important;
    text-transform: capitalize !important;
}

.mobile-login {
    background-color: white;
    width: 100%;
    height: 100vh;
    position: relative;
    background-image: url('./images/loginm.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 202px;
}


.inner-login {
    position: absolute;
    background: linear-gradient(180deg, rgba(100, 218, 183, 0.8) 0%, rgba(32, 102, 151, 0.8) 100%);
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.mobile-login-form {
    position: absolute;
    background-color: white;
    box-shadow: 2px 4px 4px rgba(31, 153, 151, 0.2);
    border-radius: 20px;
    top: 60px;
    display: block;
    text-align: center;
    width: 80vw;
    margin-left: 10%;
}

.pt-30 {
    padding-top: 30px !important;
}

.mb-30 {
    margin-bottom: 30px;
}

.small {
    font-size: 14px !important;
}

.tek-logo-mobile {
    position: fixed;
    bottom: 0;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
}

.login-flex {
    gap:16px;
    padding-left: 32px !important;
}