.chat-bot-diagram-container {
    position: absolute;
    left: 270px;
    top: 90px;
}


.run-result-container {
    background: #F9F9F9;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 23px;
    /* width: 100%; */
    padding-top: 14px;
    padding-left: 24px;
    display: block;
    padding-bottom: 10px;
}


.run-result-container-big {
    background: rgba(195, 238, 234, 0.4);
    border-radius: 7px;
    top: 90px;
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
}

.run-result-container-big .title,
.run-result-container .title {
    font-size: 9px;
    width: 100%;
    text-align: center;
    font-weight: 700;
}

.run-result-container-big .total,
.run-result-container .total {
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
    width: 100%;
    text-align: center;
}

.run-result-container-big .fail,
.run-result-container .fail {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #FB7831;
    box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.25);
}

.rec-30 {
    width: 30px !important;
    height: 30px !important;
}

.run-result-container-big .warning,
.run-result-container .warning {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #F8D271;
    box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.25);
}

.run-result-container-big .pass,
.run-result-container .pass {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #77B26B;
    box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.25);
}

.run-result-container-big .inline-title,
.run-result-container .inline-title {
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    padding-left: 8px;
    padding-top: 4px;
    width: 85%;
    position: relative;
}

.run-result-container-big .inline-title div,
.run-result-container .inline-title div {
    position: absolute;
    right: 0;
    top: 5px;
}

.middle-per {
    right: 50px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    color: #000 !important;
}

.run-result-container .inline-title:last-child {
    font-size: 15px !important;
    font-weight: 400 !important;
    color: #000 !important;
}

.run-result-container .total {

    font-weight: 700 !important;
    font-size: 29px !important;
}

.run-result-container .title {

    font-weight: 700 !important;
    font-size: 17px !important;
}

.run-result-container .inline-title span {
    font-size: 14px;
    font-weight: 500;
    color: #000;
}


.db {
    display: block;
}

.w-full {
    width: 100%;
}

.mr-16 {
    margin-right: 16px;
}

.f-18 {
    font-size: 18px;
}

.relative {
    position: relative;
}

.flex-wrap {
    flex-wrap: wrap !important;
    gap: 20px 30px !important;
}

.flex-wrap-big {
    flex-wrap: wrap !important;
    gap: 20px 30px !important;
}

.ivr-component {
    min-width: 171px;
    min-height: 54px;
    position: relative;
}

.ml-120 {
    margin: 0 auto;
    padding-right: 180px;
}

.pr-320 {
    padding-right: 210px !important;
}

.center {
    text-align: center;
}

.justify-center {
    justify-content: center;
}

.h-104 {
    min-height: 150px;
}

.telco-chart {
    position: absolute;
    top: 0px;
    height: 104px;
    left: -170px;
}

.telcobg {
    position: absolute;
    top: 45px;
    left: 45px;
}

.telcobg img:last-of-type {
    position: absolute;
    top: -29px;
    left: -3px;
}

.telcobg img:first-of-type {
    position: absolute;
    z-index: 2;
    left: 13px;
    top: -5px;
}

.upper-connection-big {
    position: absolute;
    border-bottom: 3px solid #4FB9AF;
    left: -20px;
    right: -170px;
    top: 10px;
}

.upper-connection {
    position: absolute;
    border-bottom: 3px solid #4FB9AF;
    left: -20px;
    right: -45px;
    top: 10px;
}

.chart-left-connection {
    position: absolute;
    border-bottom: 3px solid #4FB9AF;
    left: -15px;
    top: 45px;
    right: 150px;
}

.chart-left-connection-big {
    position: absolute;
    border-bottom: 3px solid #4FB9AF;
    left: -205px;
    top: 45px;
    right: 150px;
}

.pl-20 {
    padding-left: 20px;
}

.chart-right-connection {
    position: absolute;
    border-bottom: 3px solid #4FB9AF;
    right: -30px;
    top: 46px;
    left: 135px;
}

.chart-right-connection-small {
    position: absolute;
    border-bottom: 3px solid #4FB9AF;
    right: 1px;
    top: 46px;
    left: 135px;
}


.chart-right-connection-two-column {
    right: -35px !important;
}

#donut {
    position: absolute;
    top: -25px;
    left: -3px;
    width: 160px;
    height: 160px;
}

#donut-modal {
    position: absolute;
    top: -25px;
    left: -3px;
    width: 160px;
    height: 160px;
}

.pl-40 {
    padding-left: 40px;
}

.pl-210 {
    padding-left: 225px;
}

.chatbot {
    position: absolute;
    top: -33px;
    height: 104px;
    right: -265px;
    background-color: #F9F9F9;
}

.w-big {
    max-width: 1200px;
    min-width: 1200px;
}

.w-550 {
    max-width: 800px;
    min-width: 600px;
}

.w-660 {
    max-width: 850px;
    min-width: 660px;
}


.chat-small-connection {
    height: 115px;
    position: absolute;
    width: 20px;
    left: -15px;
    top: -85px;
    border-top: none;
    border-bottom: 3px solid #4FB9AF;
    border-left: 3px solid #4FB9AF;
}


.ivr-small-connection {
    height: 85px;
    position: absolute;
    width: 20px;
    left: -15px;
    top: -55px;
    border-top: none;
    border-bottom: 3px solid #4FB9AF;
    border-left: 3px solid #4FB9AF;
}

.ivr-small-connection-big {
    height: 100px;
    position: absolute;
    width: 50px;
    left: -20px;
    top: -70px;
    border-top: none;
    border-bottom: 3px solid #4FB9AF;
    border-left: 3px solid #4FB9AF;
}


.connection-left {
    position: absolute;
    height: 40px;
    right: 0;
    top: 0;
    left: -105px;
    border-top: 3px solid #4FB9AF;
    border-left: 3px solid #4FB9AF;
}


.connection-right {
    position: absolute;
    height: 50px;
    right: -105px;
    top: 0;
    bottom: 10;
    left: 0;
    border-top: 3px solid lightgray;
    border-right: 3px solid lightgrey;
}

.lens-service {
    width: 100%;
    height: 63px;
    background: linear-gradient(180deg, #B1DFDB 0%, #9AD4D2 100%);
    border-radius: 10px;
    z-index: 1;
}

.v-top {
    vertical-align: top;
}

.min-w-603 {
    min-width: 603px;
    min-height: 60px;
}

.inline {
    display: inline-block;
}

.v-middle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.flex {
    display: flex;
    align-items: center;
}

.ml-360 {
    margin-left: 390px;
}

.justify-center {
    justify-content: center;
}

.r-405 {
    right: -460px !important;
    background-color: white;
}

.p-20 {
    padding: 10px;
}

.w-700 {
    width: 700px !important;
}

.container-telco-modal {
    background: #FFF;
    overflow: visible;
    min-height: 80vh;
    padding: 0;
}

.container-telco {
    background: #F9F9F9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    min-width: 906px;
    overflow: none;
    min-height: 300px;
    padding-top: 10px;
    display: flex;
}

.container-topbar-telco {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 100%;
    overflow: none;
    min-height: 97px;
    display: flex;
    position: fixed;
    z-index: 1;
    top: 60px;
    left: 60px;
}


.gap-72 {
    gap: 72px;
}

.main-title-bar {
    position: absolute;
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    padding-top: 18px;
    color: #000000;
    padding-left: 20px;
}

.expand-icon {
    position: absolute;
    right: 0;
    padding-right: 8px;
    padding-top: 8px;
    cursor: pointer;
}

.ml-400 {
    margin-left: 410px;
}

.ml-240 {
    margin-left: 240px;
}

.telco-title {
    position: absolute;
    top: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    left: 15px;
}

.system-component-title {
    font-weight: 700;
    font-size: 23px;
    line-height: 24px;
    padding: 24px;
    width: 100%;
    color: #000;
}

.failure-count {
    position: absolute;
    right: 0;
    top: 0;
    background: #B41515;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: center;
}

.mt-32 {
    margin-top: 32px;
}

.pb-18 {
    padding-bottom: 18px;
}

.pb-5 {
    padding-bottom: 5px;
}


.summary-total .title {
    font-size: 18px !important;
    font-weight: 400 !important;
}

.details-button-container {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 10px;
}



.details-button {
    color: var(--primary-contrast, #FFF);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 200% */
    letter-spacing: 0.4px;
    text-transform: capitalize;
    display: flex;
    width: 122px;
    height: 28px;
    padding: 6px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: var(--material-theme-sys-light-primary, #006A68);

    /* elevation/2 */
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}


.box {
    display: flex;
    margin-bottom: 5px;
}

.dashboard-selection {
    margin-bottom: 10px;
    width: 154px;
    max-height: 28px;
    border-radius: 10px;
    border: 1px solid var(--Tk-1, #006A68);
    background: var(--Tk3, #CCE8E6);
    display: flex;
}

.client-journey-red {
    width: 127.88px;
    height: 127.88px;
    border-radius:70px;
    border: 2.48px solid #B43737;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.client-journey-green {
    width: 127.88px;
    height: 127.88px;
    border-radius:70px;
    border: 2.48px solid #037C6E;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width:700px){
    .container-telco {
        min-width: auto;
        padding-left: 10px;
        padding-right: 10px; 
        min-height: 250px;
    }

    .container-telco-modal {
        padding-top: 5px;
    }

    .system-component-title {
        padding: 0px;
    }

    .ivr-component {
        min-width: 0px;
    }

    .run-result-container-big {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .pl-40 {
        padding-left: 45px;
    }
}

@media (min-width:700px) {
    .run-result-container-big {
        position: absolute;
        left: 70px;
        width: 170px;
        height: 146px;
        display: block;
    }
}
