.w-171 {
    width: 181px;
    height: 54px;
    max-height: 54px;
    position: relative;
    margin-left: 4px;
}

.w-338 {
    width: 338px;
    height: 54px;
    max-height: 54px;
    position: relative;
    margin-left: 4px;
}

.agent-names-big {
    position: absolute;
    color: white;
    font-size: 10px;
    z-index: 4;
    left: 0;
    right: 140px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
}


.agent-names {
    position: absolute;
    color: white;
    font-size: 9px;
    z-index: 4;
    left: 12px;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
}

.agent-left {
    position: absolute;
    left: -6px;
    top: -3px;
    z-index: 2;
    padding: 0;
}

.agent-phone {
    position: absolute;
    left: 0;
    top: 0;
}

.dbase-title {
    position: absolute;
    color: white;
    font-size: 10px;
    top: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50px;
}

.headphone {
    position: absolute;
    right: 7px;
    z-index: 2;
    top: 15px;
}

.dbase-big {
    z-index: 3;
    position: absolute;
    right: 55px;
    top: 6px;
    display: flex;
    gap: 10px;
}

.dbase {
    z-index: 3;
    position: absolute;
    right: 35px;
    top: 13px;
}

.bg-light {
    position: absolute;
    max-height: 54px;
}